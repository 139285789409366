<template>
  <card-block>
    <div class="container">
      <div class="row">
        <div class="col">
          <p v-if="profileUpdated" class="alert alert-success">
            {{ $t('userProfile.confirmation') }}.
          </p>
        </div>
      </div>
      <Form v-slot="{ errors }" :validation-schema="profileSchema" @submit="submitForm">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <div class="form-group">
              <label for="firstName" class="fw-bold">{{ $t('userProfile.label.firstName') }}<span class="text-danger">*</span></label>
              <br>
              <Field
                type="text"
                name="firstName"
                class="form-control"
                :value="user.firstName"
                :class="{ 'is-invalid': errors.firstName }"
                :placeholder="$t('userProfile.placeholder.firstName')"
              />
              <div class="invalid-feedback">
                <ErrorMessage name="firstName" />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="form-group">
              <label for="lastName" class="fw-bold">{{ $t('userProfile.label.lastName') }}<span class="text-danger">*</span></label>
              <br>
              <Field
                type="text"
                name="lastName"
                class="form-control"
                :value="user.lastName"
                :class="{ 'is-invalid': errors.lastName }"
                :placeholder="$t('userProfile.placeholder.lastName')"
              />
              <div class="invalid-feedback">
                <ErrorMessage name="lastName" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <div class="form-group">
              <label for="email" class="fw-bold">{{ $t('userProfile.label.email') }}<span class="text-danger">*</span></label>
              <br>
              <Field
                type="email"
                name="email"
                class="form-control"
                :value="user.email"
                :class="{ 'is-invalid': errors.email }"
                :placeholder="$t('userProfile.placeholder.email')"
              />
              <div class="invalid-feedback">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="form-group">
              <label for="phoneNumber" class="fw-bold">{{ $t('userProfile.label.phoneNumber') }}<span class="text-danger">*</span></label>
              <br>
              <Field
                type="text"
                name="phoneNumber"
                class="form-control"
                :value="user.telephone"
                :class="{ 'is-invalid': errors.phoneNumber }"
                :placeholder="$t('userProfile.placeholder.phoneNumber')"
              />
              <div class="invalid-feedback">
                <ErrorMessage name="phoneNumber" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mt-md-5">
          <div class="col-12 col-md-8">
            <div class="form-check">
              <Field
                v-slot="{ field }"
                type="checkbox"
                name="marketingPermission"
                class="form-check-input"
                :unchecked-value="false"
                :value="true"
              >
                <label class="form-check-label fw-bold">
                  <input
                    id="marketingPermission"
                    type="checkbox"
                    class="form-check-input"
                    v-bind="field"
                    :value="true"
                    :checked="user.marketingPermission"
                  >
                  {{ $t('userProfile.label.marketingPermission') }}.
                </label>
              </Field>
            </div>
          </div>
          <div class="col-12 col-md-4 d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
            <button type="submit" class="btn btn-orange">
              {{ $t('userProfile.button.save') }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </card-block>
</template>

<script setup>
import CardBlock from "@/components/CardBlock.vue";
import { boolean, object, string } from "yup";
import { Form, Field, ErrorMessage } from 'vee-validate'
import { useUserStore } from "@/store/userStore"
import { storeToRefs } from "pinia"
import { ref } from "vue"

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
userStore.fetchProfile()

const profileSchema = object({
  firstName: string().required("First name required"),
  lastName: string().required("Last name required"),
  email: string().required("Please enter a valid email").email("Please enter a valid email"),
  phoneNumber: string().required("Enter a valid phone number"),
  marketingPermission: boolean().nullable().default(false),
});

let profileUpdated = ref(false)

async function submitForm(values) {
  userStore.updateUser(values)
    .then(() => {
      profileUpdated.value = true
    });
}
</script>

<style>

.step__grid-inner:not(:has(.slider__prize-info))  .spinning-wheel .spinning-wheel__inner
</style>
