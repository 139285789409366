export const Sites = {
    BoilerGuide: 1,
    SolarGuide: 2,
    GreenMatchUK: 9,
    GreenMatchDK: 10,
    EkspertvalgDK: 11,
    GreenMatchSE: 12,
    Expertvalet: 13,
    GreenMatchNO: 14,
    EkspertvalgNO: 15,
    HouseholdQuotes: 16,
    Stairlifts: 19,
    PrixPose: 20,
    GuidePiscine: 21,
    WhatCost: 23,
}

export function getLogoFromSite(site)
{
    let logo = null;
    switch (site) {
        case Sites.BoilerGuide:
            logo = 'boilerguide.svg';
            break;
        case Sites.GreenMatchDK:
        case Sites.GreenMatchSE:
        case Sites.GreenMatchUK:
        case Sites.GreenMatchNO:
            logo = 'greenmatch.svg';
            break;
        case Sites.HouseholdQuotes:
            logo = 'hhq.svg';
            break;
        case Sites.SolarGuide:
            logo = 'solarguide.svg';
            break;
        case Sites.EkspertvalgNO:
        case Sites.EkspertvalgDK:
            logo = 'ekspertvalg.svg';
            break;
        case Sites.Expertvalet:
            logo = 'expertvalet.svg';
            break;
        case Sites.Stairlifts:
            logo = 'stairliftsreviews.svg';
            break
        case Sites.PrixPose:
            logo = 'prixpose.svg';
            break
        case Sites.GuidePiscine:
            logo = 'guidepiscine.svg';
            break;
        case Sites.WhatCost:
            logo = 'whatcost.avif';
            break;
    }

    return `/logos/${logo}`;
}

export function getSiteColors(site)
{
    let colors = {
        primary: '#3C1E5F',
        secondary: '#B9B9B9',
        footerText: '#fff',
        buttons: '#FF6964',
        hoverButtons: '#F8A5A2'
    };
    switch (site) {
        case Sites.BoilerGuide:
            colors.primary = '#2BA9EF'
            colors.secondary = '#023652'
            colors.footerText = '#ffffff70'
            colors.buttons = '#E85F3C'
            colors.hoverButtons = '#F19F8A'
        break;
        case Sites.GreenMatchDK:
        case Sites.GreenMatchSE:
        case Sites.GreenMatchUK:
        case Sites.GreenMatchNO:
            colors.primary = '#72A201'
            colors.secondary = '#4E6F01'
            colors.footerText = '#ffffff70'
            colors.buttons = '#F66F04'
            colors.hoverButtons = '#F8A968'
        break;
        case Sites.HouseholdQuotes:
        case Sites.WhatCost:
            colors.primary = '#59D0EE'
            colors.secondary = '#439BBB'
            colors.footerText = '#ffffff'
            colors.buttons = '#F66F04'
            colors.hoverButtons = '#F8A968'
        break;
        case Sites.SolarGuide:
            colors.primary = '#124463'
            colors.secondary = '#092C43'
            colors.footerText = '#ffffff70'
            colors.buttons = '#F8A304'
            colors.hoverButtons = '#FAC868'
        break;
        case Sites.Stairlifts:
            colors.primary = '#1A4663'
            colors.secondary = '#09131F'
            colors.footerText = '#FFFFFF70'
            colors.buttons = '#79C400'
            colors.hoverButtons = '#AFDC66'
        break;
        case Sites.PrixPose:
            colors.primary = '#27A0DE'
            colors.secondary = '#353535'
            colors.footerText = '#FFFFFF70'
            colors.buttons = '#FF623E'
            colors.hoverButtons = '#FFA28B'
        break;
        case Sites.GuidePiscine:
            colors.primary = '#21BBEF'
            colors.secondary = '#E9ECEF'
            colors.footerText = '#212529'
            colors.buttons = '#F66F04'
            colors.hoverButtons = '#F8A968'
        break;
    }

    return colors;
}
