export default (locale, type) =>{
    return new Promise((resolve) => {
        fetch(`assets/${type}/${locale}.md`)
            .then(response => {
                if (response.ok) {
                    const content = response.text();

                    resolve(content);
                } else {
                    throw new Error(`The locale: ${locale} is not supported`);
                }
            })
            .catch((error) => {
                console.log(error)

                // Fallback to en-GB
                fetch(`assets/${type}/en-GB.md`)
                    .then(response => {
                        const content = response.text();

                        resolve(content);
                  });
            });
    });
}

