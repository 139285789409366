<template>
  <div class="from-group">
    <h3>{{ $t('login.loginEmail') }}</h3>
    <input id="email" v-model="email" type="email" class="form-control">
  </div>
  <button class="btn btn-orange mt-3" @click.stop.prevent="submit">
    {{ $t('login.submit') }}
  </button>
</template>

<script>
import {ref} from "vue";

export default {
  name: "LoginForm",
  setup(props, {emit}) {
    const email = ref('');
    function submit() {
      emit('submit', email.value);
    }

    return {email, submit}
  }
}
</script>

<style scoped lang="scss">

</style>
