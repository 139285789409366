<script>
import {useRoute} from "vue-router";
import {useEnquiryStore} from "@/store/enquiryStore";
import {computed} from "vue";
import InPageNavigation from "@/components/nav/InPageNavigation.vue";

export default {
  name: "QuoteDetailsView",
  components: {InPageNavigation},
  setup() {
    const route = useRoute();
    const enquiryStore = useEnquiryStore();
    const enquiry = computed(() => enquiryStore.selectedEnquiry);

    function getEnquiry() {
      enquiryStore.fetchEnquiry(route.params.id);
    }

    const requestedDate = computed(() => {
      if (enquiry.value) {
        return new Date(enquiry.value.requestDate);
      }
      return new Date();
    });

    getEnquiry();

    return {enquiry, requestedDate};
  }
}
</script>

<template>
  <div>
    <in-page-navigation :back-link="'/my-quotes'" />
    <div class="row mb-5">
      <div class="col">
        <div class="block-title mb-3">
          {{ enquiry?.workType?.description }}
        </div>
        <p>{{ $t('quoteDetails.createdDate:', { date: $d(requestedDate, 'shortWithTime')}) }}</p>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ $t('QuoteDetails.quotingCompany') }}
          </th>
          <th scope="col">
            {{ $t('quoteDetails.status') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="company in enquiry?.companyNames" :key="company.id">
          <td>{{ company.name }}</td>
          <td>
            {{ $t('quoteDetails.contactSoon') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">

</style>
