<template>
    <div class="row">
        <in-page-navigation :back-link="'/'" />
    </div>

    <card-block>
        <div class="container">
            <div class="row">
                <div
                    class="col"
                    v-html="pageContent"
                />
            </div>
        </div>
    </card-block>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { marked } from 'marked';
import { useUserStore } from "@/store/userStore";

import fetchLocale from "@/composables/fetchLocale";

import CardBlock from "@/components/CardBlock.vue";
import InPageNavigation from "@/components/nav/InPageNavigation.vue";
import {i18n} from "@/locales";

const pageContent = ref(null);
const userStore = useUserStore();
// Temporary fix for the locale resetting when going to a public view.
i18n.global.locale.value = userStore.user.localeFromLatestEnquiry;

const type = window.location.pathname.split('/').pop();
let locale = userStore.user.localeFromLatestEnquiry;

const getPageContent = async () => {
    if (!locale) {
        locale = 'en-GB';
    }

    pageContent.value = marked.parse(await fetchLocale(locale, type));
};

onMounted(() => {
    getPageContent();
});
</script>
